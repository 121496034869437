<template>
<div>
    <div class="wrap">
        <form name="mfrm" @submit.prevent="getStatusList" method="post">
            <div class="con_top">
                <h2><span>■</span> 상담통계</h2>
            </div>
            <div class="con mt-30">
                <div class="con_table">
                    <div class="search2 mb-30">
                        <div class="float-left dp-block mt-5">
                            <select class="w-200px" v-model="idxCrmCenter" @change="getStatusList()">
                                <option value="">전체</option>
                                <option value="0">본원센터</option>
                                <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                            </select>
                        </div>

                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <div>기간검색 :
                            <select name="birth-yyyy" v-model="startYY" class="ml-20" id="birth-yyyy">
                                <option v-for="year in parseYear()" :key="year" :value="year">{{year}}년</option>
                            </select>
                            <select name="birth-mm" v-model="startMM" class="ml-10" id="birth-mm">
                                <option v-for="index in 12" :key="index" :value="index">{{index}}월</option>
                            </select>
                            ~
                            <select name="birth-yyyy" v-model="endYY" class="ml-10" id="birth-yyyy">
                                <option v-for="year in parseYear()" :key="year" :value="year">{{year}}년</option>
                            </select>
                            <select name="birth-mm" v-model="endMM" class="ml-10" id="birth-mm">
                                <option v-for="index in 12" :key="index" :value="index">{{index}}월</option>
                            </select>
                            <input type="text" v-if="tabType === 'staff'" @keyup.enter="getStatusList()" v-model="name" class="ml-20 w-200px h-40px pd-00 pl-20" placeholder="상담사명">
                            <br>
                            <select v-model="idxCrmCompany" @change="getStatusList()" class="font-16 w-150px h-40px mt-20 pl-10">
                                <option value="-1">기업선택</option>
                                <option v-for="(item, index) of companyList" :key="index" :value="item.idx">{{item.name}}</option>
                            </select>
                            <a class="btn_search pointer" @click="getStatusList()">Search<span></span></a>
                        </div>
                    </div>

                    <div class="default_tab">
                        <div class="tabs" @click="tabType = 'staff', getStatusList()" :class="{'active': tabType === 'staff'}">
                            상담사별
                        </div>
                        <div class="tabs" @click="tabType = 'product', getStatusList()" :class="{'active': tabType === 'product'}">
                            구분별
                        </div>
                        <div class="tabs" @click="tabType = 'type', getStatusList()" :class="{'active': tabType === 'type'}">
                            유형별
                        </div>
                        <div class="tabs" @click="tabType = 'subject', getStatusList()" :class="{'active': tabType === 'subject'}">
                            주제별
                        </div>
                        <div class="tabs" @click="tabType = 'gender', getStatusList()" :class="{'active': tabType === 'gender'}">
                            남녀성비
                        </div>
                        <div class="tabs" @click="tabType = 'age', getStatusList()" :class="{'active': tabType === 'age'}">
                            연령별
                        </div>
                        <div class="tabs" @click="tabType = 'route', getStatusList()" :class="{'active': tabType === 'route'}">
                            유입경로별
                        </div>
                    </div>
                    <div class="bound_table">
                        <button type="button" class="down-btn float-right mb-20 mr-5per" @click="excelDown()">엑셀파일 다운로드</button>
                        <table v-if="tabType === 'staff'" class="default_table w-90per ml-5per">
                            <tbody v-if="loading">
                                <tr >
                                    <th>상담사명</th>
                                    <th>내담자수</th>
                                    <th>예약수</th>
                                    <th>상담진행수</th>
                                    <th>회전율</th>
                                    <th>평균회기수</th>
                                    <th>취소율</th>
                                    <th>매출액</th>
                                </tr>
                                <tr>
                                    <td colspan="8">검색중입니다.</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <th>상담사명</th>
                                    <th>내담자수</th>
                                    <th>예약수</th>
                                    <th>상담진행수</th>
                                    <th>회전율</th>
                                    <th>평균회기수</th>
                                    <th>취소율</th>
                                    <th>매출액</th>
                                </tr>
                                <tr v-for="(item, index) of status" :key="index">
                                    <td>{{item.staffName}}</td>
                                    <td>{{item.clientCnt}}</td>
                                    <td>{{item.reservationCnt}}</td>
                                    <td>{{item.reportCnt}}</td>
                                    <td>{{item.turnoverRate}}</td>
                                    <td>{{item.avgReportCnt}}</td>
                                    <td>{{item.cancelRate}}%</td>
                                    <td>{{numberWithCommas(item.totPrice)}}원</td>
                                </tr>
                                <tr v-if="status.length === 0">
                                    <td colspan="8">검색 결과가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="tabType === 'product'" class="default_table w-90per ml-5per">
                            <tbody v-if="loading">
                                <tr>
                                    <th>상품구분</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>진행수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr>
                                    <td colspan="7">검색중입니다.</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <th>상품구분</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>진행수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr v-for="(item, index) of status" :key="index">
                                    <td style="background: #FCFCFC;">{{item.type}}</td>
                                    <td>{{item.clientCnt}}</td>
                                    <td>{{item.reservationCnt}}</td>
                                    <td>{{item.reportCnt}}</td>
                                    <td>{{item.turnoverRate}}</td>
                                    <td>{{item.avgReportCnt}}</td>
                                    <td>{{item.cancelRate}}%</td>
                                </tr>
                                <tr v-if="status.length === 0">
                                    <td colspan="7">검색 결과가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="tabType === 'type'" class="default_table w-90per ml-5per">
                            <tbody v-if="loading">
                                <tr>
                                    <th>상품유형</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr>
                                    <td colspan="8">검색중입니다.</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <th>상품유형</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr v-for="(item, index) of status" :key="index">
                                    <td style="background: #FCFCFC;">{{item.type}}</td>
                                    <td>{{item.clientCnt}}</td>
                                    <td>{{item.reservationCnt}}</td>
                                    <td>{{item.reportCnt}}</td>
                                    <td>{{item.inspectCnt}}</td>
                                    <td>{{item.turnoverRate}}</td>
                                    <td>{{item.avgReportCnt}}</td>
                                    <td>{{item.cancelRate}}%</td>
                                </tr>
                                <tr v-if="status.length === 0">
                                    <td colspan="8">검색 결과가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="tabType === 'subject'" class="default_table w-90per ml-5per">
                            <tbody v-if="loading">
                                <tr>
                                    <th>상품주제</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr>
                                    <td colspan="8">검색중입니다.</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <th>상품주제</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr v-for="(item, index) of status" :key="index">
                                    <td style="background: #FCFCFC;">{{item.type}}</td>
                                    <td>{{item.clientCnt}}</td>
                                    <td>{{item.reservationCnt}}</td>
                                    <td>{{item.reportCnt}}</td>
                                    <td>{{item.inspectCnt}}</td>
                                    <td>{{item.turnoverRate}}</td>
                                    <td>{{item.avgReportCnt}}</td>
                                    <td>{{item.cancelRate}}%</td>
                                </tr>
                                <tr v-if="status.length === 0">
                                    <td colspan="8">검색 결과가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="tabType === 'gender'" class="default_table w-90per ml-5per">
                            <tbody v-if="loading">
                                <tr>
                                    <th>성별</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr>
                                    <td colspan="8">검색중입니다.</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <th>성별</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr v-for="(item, index) of status" :key="index">
                                    <td style="background: #FCFCFC;">{{item.type}}</td>
                                    <td>{{item.clientCnt}}</td>
                                    <td>{{item.reservationCnt}}</td>
                                    <td>{{item.reportCnt}}</td>
                                    <td>{{item.inspectCnt}}</td>
                                    <td>{{item.turnoverRate}}</td>
                                    <td>{{item.avgReportCnt}}</td>
                                    <td>{{item.cancelRate}}%</td>
                                </tr>
                                <tr v-if="status.length === 0">
                                    <td colspan="8">검색 결과가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="tabType === 'age'" class="default_table w-90per ml-5per">
                            <tbody v-if="loading">
                                 <tr>
                                    <th>연령</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr>
                                    <td colspan="8">검색중입니다.</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <th>연령</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr v-for="(item, index) of status" :key="index">
                                    <td style="background: #FCFCFC;">{{item.type}}</td>
                                    <td>{{item.clientCnt}}</td>
                                    <td>{{item.reservationCnt}}</td>
                                    <td>{{item.reportCnt}}</td>
                                    <td>{{item.inspectCnt}}</td>
                                    <td>{{item.turnoverRate}}</td>
                                    <td>{{item.avgReportCnt}}</td>
                                    <td>{{item.cancelRate}}%</td>
                                </tr>
                                <tr v-if="status.length === 0">
                                    <td colspan="8">검색 결과가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="tabType === 'route'" class="default_table w-90per ml-5per">
                            <tbody v-if="loading">
                                 <tr>
                                    <th>유입경로</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr>
                                    <td colspan="8">검색중입니다.</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <th>유입경로</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr v-for="(item, index) of status" :key="index">
                                    <td style="background: #FCFCFC;">{{item.type}}</td>
                                    <td>{{item.clientCnt}}</td>
                                    <td>{{item.reservationCnt}}</td>
                                    <td>{{item.reportCnt}}</td>
                                    <td>{{item.inspectCnt}}</td>
                                    <td>{{item.turnoverRate}}</td>
                                    <td>{{item.avgReportCnt}}</td>
                                    <td>{{item.cancelRate}}%</td>
                                </tr>
                                <tr v-if="status.length === 0">
                                    <td colspan="8">검색 결과가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import FileSaver from 'file-saver'
export default {
    components: {

    },
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜
        name: '',
        idxCrmCenter: '',
        idxCrmCompany: -1,
        centerList: [],
        companyList: [],
        startYY: 2020,
        startMM: 1,
        endYY: 2020,
        endMM: 1,

        tabType: 'staff', // 탭 타입

        status: [],
        loading:true,
    }),

    mounted() {
        let date = new Date()
        this.startYY = date.getFullYear();
        this.startMM = date.getMonth() + 1;
        this.endYY = date.getFullYear();
        this.endMM = date.getMonth() + 1;
        this.getCenterListAll();
        this.getCompanyListAll();
        this.getStatusList();
    },
    methods: {

        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },

        // 현재 년도에 따라서 120년만큼 selectbox
        parseYear() {
            var nowYear = new Date().getFullYear()
            var parseYear = []
            for (var i = 0; i <= 120; i++) {
                parseYear.push(nowYear - i)
            }
            return parseYear
        },

        getCenterListAll() {

            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                    }
                })
                .catch(err => {
                    alert(err)
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 소속리스트(기관리스트)
        getCompanyListAll() {
            this.axios.get('/api/v1/company/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.companyList = res.data.companyList
                    } else {
                        //alert('소속리스트 결과가 없습니다.')
                    }
                })
                .catch(err => {
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        getStatusList() {

            let url = ""

            if (this.tabType === "staff") {
                url = "/main/staff"
            } else if (this.tabType === "product") {
                url = "/main/product"
            } else if (this.tabType === "type") {
                url = "/main/type"
            } else if (this.tabType === "subject") {
                url = "/main/subject"
            } else if (this.tabType === "gender") {
                url = "/main/gender"
            } else if (this.tabType === "age") {
                url = "/main/age"
            } else {
                url = "/main/route"
            }

            let tempMM = ""
            // 날짜 데이터 파싱
            if (this.startMM < 10) {
                tempMM = "0" + this.startMM
            } else {
                tempMM = this.startMM
            }
            let startDate = this.startYY.toString() + '-' + tempMM

            if (this.endMM < 10) {
                tempMM = "0" + this.endMM
            } else {
                tempMM = this.endMM
            }
            let endDate = this.endYY.toString() + '-' + tempMM

            let params = {
                name: this.name,
                idxCrmCompany: this.idxCrmCompany,
                idxCrmCenter: this.idxCrmCenter,
                startDate: startDate,
                endDate: endDate
            };

            this.loading = true;

            this.axios.get('/api/v1/status' + url, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.status = res.data.status;
                    } else {
                        this.status = [];
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
                .finally(()=>{
                    this.loading = false;
                });
        },

        excelDown() {

            //alert(this.tabType)

            let url = "";

            if (this.tabType === "staff" || this.tabType === 'product') {
                url = "/status-main"
            } else {
                url = "/status-main2"
            }

            // 날짜 데이터 파싱
            if (this.startMM < 10) {
                this.startMM = "0" + this.startMM
            }
            let startDate = this.startYY.toString() + '-' + this.startMM.toString()

            if (this.endMM < 10) {
                this.endMM = "0" + this.endMM
            }
            let endDate = this.endYY.toString() + '-' + this.endMM.toString()

            let params = {
                type: this.tabType,
                name: this.name,
                idxCrmCompany: this.idxCrmCompany,
                idxCrmCenter: this.idxCrmCenter,
                startDate: startDate,
                endDate: endDate
            }

            let headers = {
                //'Content-Disposition': "attachment; filename=" + fileName + ".xlsx",
                'Content-Type': 'ms-vnd/excel;charset=UTF-8'
            }

            this.axios.defaults.headers.post[headers]

            this.axios.get('/api/v1/excel' + url, {
                params: params,
                responseType: 'arraybuffer'
            }).then(res => {
                FileSaver.saveAs(new Blob([res.data]), "status_main_template.xlsx")
            }).catch(err => {
                console.log(err)
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            })

        },

    }
}
</script>
